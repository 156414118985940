@tailwind base;
@tailwind components;
@tailwind utilities;

.line-clamp-4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}
.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
/* body {
  overflow-x: hidden;
  overflow-y: auto;
  white-space: nowrap;
} */

/* ::-webkit-scrollbar {
  display: none;
} */

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.custom-show-more-link {
  color: red;
  font-weight: 600;
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  border-radius: 0.375rem;
  transition: background-color 0.2s ease-in-out;
}

.custom-show-more-link:hover {
  background-color: #eff6ff;
}

.fc-popover {
  background-color: white;
  border: 1px solid #e5e7eb;
  z-index: 10;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  padding: 0.5rem;
  max-width: 300px;
}

.fc-popover .fc-popover-header {
  font-weight: 600;
  background-color: #f3f4f6;
  padding: 0.5rem;
  border-bottom: 1px solid #e5e7eb;
  border-radius: 0.5rem 0.5rem 0 0;
}

.fc-popover .fc-event {
  padding: 0.5rem;
  margin: 0.25rem 0;
  border-radius: 0.375rem;
  transition: background-color 0.2s ease-in-out;
}

.fc-popover .fc-event:hover {
  background-color: #f3f4f6;
  cursor: pointer;
}

.fc-popover .fc-event .fc-event-dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.fc-popover .fc-event-title {
  font-weight: 600;
  color: #374151;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fc-popover .fc-event-time {
  color: #6b7280;
  margin-right: 0.5rem;
}
/* .fc-daygrid-day {
  height: 130px;
} */

.user-drawer-scroll {
  overflow-y: auto;
}

.user-drawer-scroll::-webkit-scrollbar {
  display: none;
}

.user-drawer-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
